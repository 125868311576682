import { default as indexuHHRmv2elTMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/auth/index.vue?macro=true";
import { default as indexiEGBn3PVfKMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexOIqTYOt3SPMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/index.vue?macro=true";
import { default as organisationBaseDataRcUkuyMvXqMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93D5oR0D03bfMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/organisations/[id].vue?macro=true";
import { default as index7HAmYluR34Meta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/organisations/index.vue?macro=true";
import { default as languageEq0nlf6Gl7Meta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings/language.vue?macro=true";
import { default as _91id_93tQNkhCh59rMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as index7uMXO7dRehMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as organisationMkZ3kKP6R9Meta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings/organisation.vue?macro=true";
import { default as roles2vKQEMdgM8Meta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings/roles.vue?macro=true";
import { default as settingszLUuEo1g6ZMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings.vue?macro=true";
import { default as userActivitiesoNO6ux9atfMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDatagCvTlSJKvgMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsymency7fjgMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93ZIGQXnTw3VMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users/[id].vue?macro=true";
import { default as indextNeWKYlULMMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users/index.vue?macro=true";
import { default as usersgsALGq5SRpMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93aTuVtToG5AMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: indexuHHRmv2elTMeta?.name ?? "auth",
    path: indexuHHRmv2elTMeta?.path ?? "/auth",
    meta: indexuHHRmv2elTMeta || {},
    alias: indexuHHRmv2elTMeta?.alias || [],
    redirect: indexuHHRmv2elTMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexiEGBn3PVfKMeta?.name ?? "auth-social-organisation-provider",
    path: indexiEGBn3PVfKMeta?.path ?? "/auth/social/:organisation()/:provider()",
    meta: indexiEGBn3PVfKMeta || {},
    alias: indexiEGBn3PVfKMeta?.alias || [],
    redirect: indexiEGBn3PVfKMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOIqTYOt3SPMeta?.name ?? "index",
    path: indexOIqTYOt3SPMeta?.path ?? "/",
    meta: indexOIqTYOt3SPMeta || {},
    alias: indexOIqTYOt3SPMeta?.alias || [],
    redirect: indexOIqTYOt3SPMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93D5oR0D03bfMeta?.name ?? "organisations-id",
    path: _91id_93D5oR0D03bfMeta?.path ?? "/organisations/:id()",
    children: [
  {
    name: organisationBaseDataRcUkuyMvXqMeta?.name ?? "organisations-id-organisationBaseData",
    path: organisationBaseDataRcUkuyMvXqMeta?.path ?? "organisationBaseData",
    meta: organisationBaseDataRcUkuyMvXqMeta || {},
    alias: organisationBaseDataRcUkuyMvXqMeta?.alias || [],
    redirect: organisationBaseDataRcUkuyMvXqMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93D5oR0D03bfMeta || {},
    alias: _91id_93D5oR0D03bfMeta?.alias || [],
    redirect: _91id_93D5oR0D03bfMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: index7HAmYluR34Meta?.name ?? "organisations",
    path: index7HAmYluR34Meta?.path ?? "/organisations",
    meta: index7HAmYluR34Meta || {},
    alias: index7HAmYluR34Meta?.alias || [],
    redirect: index7HAmYluR34Meta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: settingszLUuEo1g6ZMeta?.name ?? "settings",
    path: settingszLUuEo1g6ZMeta?.path ?? "/settings",
    children: [
  {
    name: languageEq0nlf6Gl7Meta?.name ?? "settings-language",
    path: languageEq0nlf6Gl7Meta?.path ?? "language",
    meta: languageEq0nlf6Gl7Meta || {},
    alias: languageEq0nlf6Gl7Meta?.alias || [],
    redirect: languageEq0nlf6Gl7Meta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tQNkhCh59rMeta?.name ?? "settings-loginProviders-id",
    path: _91id_93tQNkhCh59rMeta?.path ?? "loginProviders/:id()",
    meta: _91id_93tQNkhCh59rMeta || {},
    alias: _91id_93tQNkhCh59rMeta?.alias || [],
    redirect: _91id_93tQNkhCh59rMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: index7uMXO7dRehMeta?.name ?? "settings-loginProviders",
    path: index7uMXO7dRehMeta?.path ?? "loginProviders",
    meta: index7uMXO7dRehMeta || {},
    alias: index7uMXO7dRehMeta?.alias || [],
    redirect: index7uMXO7dRehMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: organisationMkZ3kKP6R9Meta?.name ?? "settings-organisation",
    path: organisationMkZ3kKP6R9Meta?.path ?? "organisation",
    meta: organisationMkZ3kKP6R9Meta || {},
    alias: organisationMkZ3kKP6R9Meta?.alias || [],
    redirect: organisationMkZ3kKP6R9Meta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings/organisation.vue").then(m => m.default || m)
  },
  {
    name: roles2vKQEMdgM8Meta?.name ?? "settings-roles",
    path: roles2vKQEMdgM8Meta?.path ?? "roles",
    meta: roles2vKQEMdgM8Meta || {},
    alias: roles2vKQEMdgM8Meta?.alias || [],
    redirect: roles2vKQEMdgM8Meta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings/roles.vue").then(m => m.default || m)
  }
],
    meta: settingszLUuEo1g6ZMeta || {},
    alias: settingszLUuEo1g6ZMeta?.alias || [],
    redirect: settingszLUuEo1g6ZMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/settings.vue").then(m => m.default || m)
  },
  {
    path: usersgsALGq5SRpMeta?.path ?? "/users",
    children: [
  {
    name: _91id_93ZIGQXnTw3VMeta?.name ?? "users-id",
    path: _91id_93ZIGQXnTw3VMeta?.path ?? ":id()",
    children: [
  {
    name: userActivitiesoNO6ux9atfMeta?.name ?? "users-id-userActivities",
    path: userActivitiesoNO6ux9atfMeta?.path ?? "userActivities",
    meta: userActivitiesoNO6ux9atfMeta || {},
    alias: userActivitiesoNO6ux9atfMeta?.alias || [],
    redirect: userActivitiesoNO6ux9atfMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: userBaseDatagCvTlSJKvgMeta?.name ?? "users-id-userBaseData",
    path: userBaseDatagCvTlSJKvgMeta?.path ?? "userBaseData",
    meta: userBaseDatagCvTlSJKvgMeta || {},
    alias: userBaseDatagCvTlSJKvgMeta?.alias || [],
    redirect: userBaseDatagCvTlSJKvgMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: userCommunicationsymency7fjgMeta?.name ?? "users-id-userCommunications",
    path: userCommunicationsymency7fjgMeta?.path ?? "userCommunications",
    meta: userCommunicationsymency7fjgMeta || {},
    alias: userCommunicationsymency7fjgMeta?.alias || [],
    redirect: userCommunicationsymency7fjgMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
],
    meta: _91id_93ZIGQXnTw3VMeta || {},
    alias: _91id_93ZIGQXnTw3VMeta?.alias || [],
    redirect: _91id_93ZIGQXnTw3VMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indextNeWKYlULMMeta?.name ?? "users",
    path: indextNeWKYlULMMeta?.path ?? "",
    meta: indextNeWKYlULMMeta || {},
    alias: indextNeWKYlULMMeta?.alias || [],
    redirect: indextNeWKYlULMMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users/index.vue").then(m => m.default || m)
  }
],
    name: usersgsALGq5SRpMeta?.name ?? undefined,
    meta: usersgsALGq5SRpMeta || {},
    alias: usersgsALGq5SRpMeta?.alias || [],
    redirect: usersgsALGq5SRpMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/users.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93aTuVtToG5AMeta?.name ?? "wiki-slug",
    path: _91_46_46_46slug_93aTuVtToG5AMeta?.path ?? "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93aTuVtToG5AMeta || {},
    alias: _91_46_46_46slug_93aTuVtToG5AMeta?.alias || [],
    redirect: _91_46_46_46slug_93aTuVtToG5AMeta?.redirect || undefined,
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20240610074417/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]