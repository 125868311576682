import { defineNuxtPlugin } from '#app'
import { abilitiesPlugin } from '@casl/vue'
import { Ability } from '@casl/ability'

export default defineNuxtPlugin((nuxtApp) => {

    const ability = new Ability([])
    nuxtApp.provide('abilitySingleton', ability)

    //Use the created ability as the default one for the abilityPlugin
    nuxtApp.vueApp.use(abilitiesPlugin,ability,{useGlobalProperties: true})
});