import { defineNuxtPlugin } from '#app/nuxt'
import { LazyTableFilterBoolean, LazyTableFilterNewFilterTemplate, LazyTableFilterNumeric, LazyTableFilterSelect, LazyTableFilterString, LazyTableToolGeneralExport, LazyTableToolLoginProvidersCreate, LazyTableToolOrganisationsCreate, LazyTableToolOrganisationsToggle, LazyTableToolUsersCreate, LazyTableToolUsersDelete, LazyBasicHeading, LazyBasicText, LazySubheading, LazyPostLoader, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["TableFilterBoolean", LazyTableFilterBoolean],
["TableFilterNewFilterTemplate", LazyTableFilterNewFilterTemplate],
["TableFilterNumeric", LazyTableFilterNumeric],
["TableFilterSelect", LazyTableFilterSelect],
["TableFilterString", LazyTableFilterString],
["TableToolGeneralExport", LazyTableToolGeneralExport],
["TableToolLoginProvidersCreate", LazyTableToolLoginProvidersCreate],
["TableToolOrganisationsCreate", LazyTableToolOrganisationsCreate],
["TableToolOrganisationsToggle", LazyTableToolOrganisationsToggle],
["TableToolUsersCreate", LazyTableToolUsersCreate],
["TableToolUsersDelete", LazyTableToolUsersDelete],
["BasicHeading", LazyBasicHeading],
["BasicText", LazyBasicText],
["Subheading", LazySubheading],
["PostLoader", LazyPostLoader],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
